<script setup lang="ts">
import type {
  CartVoucherProduct,
  VoucherDesign,
  VoucherProduct,
  VoucherProductCustomisation,
} from '#gql/default'
import { useFormKitContextById } from '@formkit/vue'

interface Props {
  voucherProduct: VoucherProduct
  cartVoucherProduct?: CartVoucherProduct
}

const { voucherProduct, cartVoucherProduct } = defineProps<Props>()
const { t, n, locale } = useI18n()
const { setPanel, cinema } = await useVoucherShop()
const { addToCart } = await useVoucherCart({
  cinema: cinema.value,
})

const formId = useId()
const formContext = useFormKitContextById<{
  state: {
    loading: boolean
    disabled: boolean
  }
}>(formId)

const isFormSubmitting = computed(() =>
  Boolean(
    formContext.value?.state?.loading || formContext.value?.state?.disabled,
  ),
)

interface FormData {
  quantity: number
  selectedAmount?: number
  voucherDesignId?: string
  customisations: VoucherProductCustomisation[]
}

const formData = ref<FormData>({
  quantity: cartVoucherProduct?.quantity ?? 1,
  selectedAmount: cartVoucherProduct?.selectedAmount,
  voucherDesignId:
    cartVoucherProduct?.voucherDesignId ||
    (voucherProduct.voucherDesigns.length === 1
      ? voucherProduct.voucherDesigns[0].id
      : ''),
  customisations: cartVoucherProduct?.customisations || [],
})

const designOptions = computed(() =>
  voucherProduct.voucherDesigns.map((design: VoucherDesign) => ({
    label: design.name,
    value: design.id,
    thumbnail: design.thumbnail,
  })),
)

const amountOptions = computed(() => [
  { label: t('label.giftCardValue'), value: '' },
  ...(voucherProduct.priceOptions?.filter(Boolean).map((priceOption) => ({
    label: n(priceOption?.amount, 'currency'),
    value: priceOption?.amount,
  })) || []),
])

async function handleSubmit(data: any) {
  await addToCart({
    ...data,
    voucherProduct,
    cartVoucherProduct,
    locale: locale.value,
    onSuccess: () => {
      setPanel(VOUCHER_SHOP_PANEL.CART)
    },
  })
}

function updateCustomisation(index: number, data: any) {
  if (!formData.value.customisations) {
    formData.value.customisations = []
  }

  // Ensure array has enough slots
  while (formData.value.customisations.length < formData.value.quantity) {
    formData.value.customisations.push({})
  }

  // Update the specific customisation
  formData.value.customisations[index] = data
}

defineOptions({
  name: 'VoucherProductFormConfigure',
})
</script>

<template>
  <div>
    <FormKit
      :id="formId"
      v-model="formData"
      type="form"
      :actions="false"
      @submit="handleSubmit"
    >
      <div class="space-y-6">
        <FormKit
          type="select"
          :floating-label="true"
          :label="t('label.giftCardValue')"
          name="selectedAmount"
          :options="amountOptions"
          validation="required"
        />

        <FormKit
          :label="t('label.quantity')"
          name="quantity"
          type="number"
          :min="1"
          :step="1"
          :max="10"
          :value="String(formData.quantity)"
          maxlength="2"
        />

        <FormKit
          v-if="voucherProduct!.voucherDesigns.length > 1"
          :label="t('label.design')"
          name="voucherDesignId"
          required
          validation="required"
          type="radio"
          :tile="true"
          :options="designOptions"
          :classes="{
            options: 'grid !grid-cols-3 !gap-4',
            decorator: '!hidden',
          }"
        >
          <template #label="{ option }">
            <div class="flex flex-col items-center space-y-2">
              <VoucherProductFormConfigureDesignOption :option />
              <span class="text-sm" v-text="option.label" />
            </div>
          </template>
        </FormKit>

        <div v-if="voucherProduct?.enableCustomisation" class="space-y-4">
          <div v-for="i in formData.quantity" :key="`customisation-${i}`">
            <Divider
              v-if="formData.quantity > 1"
              :label="t('dividerLabel', { index: i })"
            />

            <VoucherProductFormConfigureCustomisation
              :index="i - 1"
              :customisation="formData.customisations[i - 1]"
              :title="`${t('title.personalisation')} ${formData.quantity > 1 ? t('dividerLabel', { index: i }) : ''}`"
              :disabled="isFormSubmitting"
              @update="updateCustomisation(i - 1, $event)"
            />
          </div>
        </div>

        <div class="grid grid-cols-1 gap-2 pt-9 md:grid-cols-2">
          <VoucherProductPreview
            :voucher-product-id="voucherProduct.id"
            :voucher-design-id="formData.voucherDesignId || ''"
            :customisations="formData.customisations"
            :selected-amount="Number(formData.selectedAmount) || 0"
            :quantity="formData.quantity"
            :disabled="isFormSubmitting"
          />

          <KButton
            :text="!!cartVoucherProduct ? t('btn.update') : t('btn.addToCart')"
            :disabled="
              isFormSubmitting ||
              !formData.selectedAmount ||
              !formData.voucherDesignId
            "
            :type="BUTTON_TYPE.SUBMIT"
          />
        </div>
      </div>
    </FormKit>
  </div>
</template>

<i18n>
de:
  title:
    personalisation: "Personalisierung"
    shipping: "Versand"
  optional: "(optional)"
  dividerLabel: "Gutschein {index}"
  label:
    giftCardValue: "Gutscheinwert"
    design: "Design"
    quantity: "Anzahl"
  btn:
    addToCart: "Zum Warenkorb hinzufügen"
    update: "Aktualisieren"
es:
  title:
    personalisation: "Personalización"
    shipping: "Envío"
  optional: "(opcional)"
  dividerLabel: "Tarjeta de regalo {index}"
  label:
    giftCardValue: "Valor de la tarjeta de regalo"
    design: "Diseño"
    quantity: "Cantidad"
  btn:
    addToCart: "Añadir al carrito"
    update: "Actualizar"
</i18n>
