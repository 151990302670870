<script setup lang="ts">
const { shopData } = await useConcessionShop()

const hasMultipleCategories = computed(
  () => (shopData.value?.categories?.length ?? 0) > 1,
)
function scrollToCategory(id: string) {
  const categoryHeader = document.querySelector(
    `[data-concession-category="${id}"]`,
  )

  if (categoryHeader) {
    categoryHeader.scrollIntoView({ block: 'start' })
  }
}

defineOptions({
  name: 'ConcessionCategories',
})
</script>

<template>
  <KCarousel container-classes="gap-2" :theme="BUTTON_THEME.SECONDARY">
    <FiltersSearch :collapse="(shopData?.categories?.length ?? 0) > 1" />
    <template v-if="hasMultipleCategories">
      <div v-for="{ id, name } in shopData?.categories" :key="id">
        <KButton
          :theme="BUTTON_THEME.SECONDARY"
          :title="name"
          :text="name"
          @click="scrollToCategory(id)"
        />
      </div>
    </template>
  </KCarousel>
</template>
