<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

interface ButtonClasses {
  left?: {
    background?: string
    position?: string
    container?: string
  }
  right?: {
    background?: string
    position?: string
    container?: string
  }
}

interface Props {
  containerClasses?: string
  buttonClasses?: ButtonClasses
}

const { containerClasses = '', buttonClasses = { left: {}, right: {} } } =
  defineProps<Props>()

const scrollContainer = ref<HTMLElement | null>(null)

const { icons } = useDesign()
const { arrivedState } = useScroll(scrollContainer)
const { width } = useElementSize(scrollContainer)
const breakpoints = useBreakpoints(breakpointsTailwind)

function updateScrollState() {
  if (scrollContainer.value) {
    const { scrollWidth, clientWidth } = scrollContainer.value
    arrivedState.right = scrollWidth <= clientWidth
    arrivedState.left = scrollContainer.value.scrollLeft <= 0
  }
}

const breakpoint = breakpoints.greaterOrEqual('sm')

const isScrollLeftVisible = computed(() => {
  if (import.meta.client && breakpoint.value) {
    return !arrivedState.left
  } else {
    return false
  }
})

const isScrollRightVisible = computed(() => {
  if (import.meta.client && breakpoint.value) {
    return !arrivedState.right
  } else {
    return false
  }
})

const defaultButtonClasses = {
  left: {
    background: 'bg-gradient-to-r from-body from-85% to-transparent',
    position: 'absolute top-1/2 z-10 h-full w-auto -translate-y-1/2 py-2',
    container: '-left-[var(--container-padding)] pr-2 pl-2',
  },
  right: {
    background: 'bg-gradient-to-l from-body from-85% to-transparent',
    position: 'absolute top-1/2 z-10 h-full w-auto -translate-y-1/2 py-2',
    container: '-right-[var(--container-padding)] pl-2 pr-2',
  },
}

const mergedLeftButtonClasses = computed(() => {
  const defaultClasses = Object.values(defaultButtonClasses.left).join(' ')
  const customClasses = buttonClasses?.left
    ? Object.values(buttonClasses.left).join(' ')
    : ''
  return twMerge(defaultClasses, customClasses)
})

const mergedRightButtonClasses = computed(() => {
  const defaultClasses = Object.values(defaultButtonClasses.right).join(' ')
  const customClasses = buttonClasses?.right
    ? Object.values(buttonClasses.right).join(' ')
    : ''
  return twMerge(defaultClasses, customClasses)
})

const mergedContainerClasses = computed(() => {
  const classes = [
    '[&>div:first-child]:pl-[var(--container-padding)] sm:[&>div:first-child]:pl-1 [&>div]:py-2 scrollbar-none',
    ' group-data-[default-horizontal-spacing=true]:container-bleed sm:group-data-[default-horizontal-spacing=true]:container-bleed-none flex overflow-x-auto',
  ]

  return twMerge(classes, containerClasses)
})

function scroll(direction: 'left' | 'right') {
  if (!scrollContainer.value) {
    return
  }

  const container = scrollContainer.value
  const containerRect = container.getBoundingClientRect()
  const items = Array.from(container.children) as HTMLElement[]

  if (direction === 'right') {
    const nextItem = items.find((item) => {
      const rect = item.getBoundingClientRect()
      return rect.right > containerRect.right
    })

    if (nextItem) {
      const scrollAmount = nextItem.offsetLeft - container.scrollLeft
      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      })
    }
  } else {
    container.scrollBy({
      left: -containerRect.width,
      behavior: 'smooth',
    })
  }
}

watch(width, () => {
  nextTick(updateScrollState)
})

onMounted(() => {
  nextTick(updateScrollState)
})

defineOptions({
  name: 'KCarousel',
})
</script>

<template>
  <div data-horizontal-scroll-wrapper class="relative">
    <TransitionFade>
      <div v-if="isScrollLeftVisible" :class="mergedLeftButtonClasses">
        <KButton
          :icon="icons.CHEVRON_LEFT"
          theme="secondary"
          @click="scroll('left')"
          aria-hidden="true"
        />
      </div>
    </TransitionFade>

    <div
      data-horizontal-scroll-container
      ref="scrollContainer"
      :class="mergedContainerClasses"
    >
      <slot />
    </div>

    <TransitionFade>
      <div v-if="isScrollRightVisible" :class="mergedRightButtonClasses">
        <KButton
          :icon="icons.CHEVRON_RIGHT"
          theme="secondary"
          @click="scroll('right')"
          aria-hidden="true"
        />
      </div>
    </TransitionFade>
  </div>
</template>
