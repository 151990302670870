<script setup lang="ts">
import type { CartVoucherProduct, VoucherDesign } from '#gql/default'

interface Props {
  cartVoucherProduct: CartVoucherProduct
}

const props = defineProps<Props>()
const { setPanel, cinema } = await useVoucherShop()
const { addToCart, pending } = await useVoucherCart({ cinema: cinema.value })
const { t, n, locale } = useI18n()

const voucherDesign = computed(
  () =>
    props.cartVoucherProduct.voucherProduct.voucherDesigns.find(
      (design: VoucherDesign) =>
        design.id === props.cartVoucherProduct.voucherDesignId,
    )?.name,
)
const selectedPriceOption = computed(() =>
  props.cartVoucherProduct.voucherProduct.priceOptions.find(
    (priceOption: { amount: number; price: number }) =>
      priceOption.amount === props.cartVoucherProduct.selectedAmount,
  ),
)

async function handleUpdate({ quantity }: { quantity: number }) {
  if (pending.value) {
    return
  }

  await addToCart({
    quantity,
    selectedAmount: props.cartVoucherProduct.selectedAmount,
    voucherDesignId: props.cartVoucherProduct.voucherDesignId,
    customisations: props.cartVoucherProduct.customisations,
    cartVoucherProduct: props.cartVoucherProduct,
    voucherProduct: props.cartVoucherProduct.voucherProduct,
    locale: locale.value,
  })
}

function handleEdit() {
  setPanel(VOUCHER_SHOP_PANEL.CONFIGURE, {
    cartVoucherId: props.cartVoucherProduct.id,
  })
}

defineOptions({
  name: 'CartVoucherProduct',
})
</script>

<template>
  <div
    class="grid gap-y-4 py-4 transition-opacity duration-300"
    :class="{ 'pointer-events-none opacity-25': pending }"
  >
    <div class="grid grid-cols-[auto,1fr] items-center gap-x-6">
      <div
        class="font-bold"
        v-text="
          formatQuantityAndName(
            cartVoucherProduct.quantity,
            cartVoucherProduct.voucherProduct.name,
          )
        "
      />
      <div
        class="text-right font-bold"
        v-text="n(cartVoucherProduct.price.total, 'currency')"
      />
    </div>

    <div class="grid grid-cols-[auto,1fr] gap-x-6 gap-y-0.5">
      <div v-text="t('design')" />
      <div v-text="voucherDesign" />

      <div v-text="t('amount')" />
      <div v-text="n(selectedPriceOption?.amount, 'currency')" />

      <template v-if="selectedPriceOption.amount !== selectedPriceOption.price">
        <div v-text="t('price')" />
        <div v-text="n(selectedPriceOption?.price, 'currency')" />
      </template>

      <template v-if="cartVoucherProduct.customisations.length">
        <div class="mt-2" v-text="t('customisation')" />
        <div class="col-span-2">
          <div
            v-for="({ text }, index) in cartVoucherProduct.customisations"
            :key="index"
            class="grid grid-cols-[auto,1fr] gap-x-6 py-1"
          >
            <div
              v-if="cartVoucherProduct.customisations.length > 1"
              v-text="t('voucher', { index: index + 1 })"
            />
            <div
              class="col-span-full whitespace-pre-line break-words"
              v-html="text"
            />
          </div>
        </div>
      </template>
    </div>

    <CartFormUpdateItem
      :quantity="cartVoucherProduct.quantity"
      class="mt-2"
      @update="handleUpdate"
      @edit="handleEdit"
    />
  </div>
</template>

<i18n>
de:
  design: "Design:"
  amount: "Wert:"
  price: "Preis:"
  customisation: "Personalisierung:"
  voucher: "Gutschein {index}:"
en:
  design: "Design:"
  amount: "Value:"
  price: "Price:"
  customisation: "Personalisation:"
  voucher: "Voucher {index}:"
es:
  design: "Design:"
  amount: "Valor:"
  price: "Precio:"
  customisation: "Personalización:"
  voucher: "Tarjeta {index}:"
</i18n>
